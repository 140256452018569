import { Checkbox } from "@mui/material";
import React from "react";
import { CheckedSquare, Delete } from "../../assets/Icons";

const ReviewOptions = ({
	selected,
	currentItems,
	handleSelectAllClick,
	permissions,
	setNotificationTitle,
	setItems,
	setActionType,
}) => {
	return (
		<div className='flex flex-row items-center gap-[53px]'>
			<div className='flex flex-row items-center gap-3'>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						pr: "0",
						color: "#011723",
						"& .MuiSvgIcon-root": {
							color: "#011723",
						},
					}}
					indeterminate={
						selected?.length > 0 && selected?.length < currentItems?.length
					}
					checked={
						currentItems?.length > 0 &&
						selected?.length === currentItems?.length
					}
					onChange={handleSelectAllClick}
					inputProps={{
						"aria-label": "select all desserts",
					}}
				/>
				<label
					className='md:text-[18px] text-[16px]'
					style={{ color: "#011723" }}
					htmlFor='all'>
					تحديد الكل
				</label>
			</div>

			<div className='flex flex-row items-center justify-center gap-2'>
				{permissions?.includes("admin.sament.deleteall") &&
					selected?.length > 0 && (
						<div
							className='flex flex-row items-center justify-center cursor-pointer'
							style={{
								width: "130px",
								height: "40px",
								backgroundColor: "#FF38381A",
								borderRadius: "20px",
							}}
							onClick={() => {
								setNotificationTitle(
									"سيتم حذف جميع التقييمات التي قمت بتحديدها"
								);
								setItems(selected);
								setActionType("deleteAll");
							}}>
							<Delete />
							<h6
								className='md:text-[18px] text-[16px] font-medium'
								style={{ color: "#FF3838" }}>
								حذف الكل
							</h6>
						</div>
					)}
			</div>
		</div>
	);
};

export default ReviewOptions;
